import React from "react";
import logo from "./sd_logo.png";
import appImage from "./app_image.png";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
            padding: 40,
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ borderRadius: 20, height: 100, marginRight: 20 }}
          />
        </div>
        {/* Put appImage on the right and there should be a text on the left */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: 20,
          }}
        >
          <div style={{ width: "50%" }}>
            {/* Start the both text from same position */}
            <h1 style={{ fontSize: 40, textAlign: "left", margin: 15 }}>
              Sokaktaki Dostlarım
            </h1>
            <p style={{ fontSize: 20 }}>
              Sokaktaki Dostlarım uygulaması, sokak hayvanlarının beslenmesi
              için yapılmış bir uygulamadır.
            </p>
          </div>
          <img
            src={appImage}
            alt="appImage"
            style={{ height: 800, borderRadius: 20, marginRight: 60 }}
          />
        </div>

        {/* Create a footer with privacy policy link put links in the center */}
        <footer
          style={{
            position: "absolute",
            bottom: 0,
            marginBottom: 20,
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <a
            href="/privacy-policy"
            style={{
              color: "#61dafb",
              textDecoration: "none",
              fontSize: 14,
              justifyContent: "center",
            }}
          >
            Privacy Policy
          </a>
        </footer>
      </header>
    </div>
  );
}

export default Home;
