import PrivacyPolicy from './pages/policies/PrivacyPolicy';
import Home from './pages/home';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
