import React from "react";

function PrivacyPolicy() {
  return (
    <div>
      <h3>Sokaktaki Dostlarim Privacy Policy</h3>
      <p style={{ padding: 50 }}>
        This privacy policy ("Policy") describes how Sokaktaki Dostlarim
        ("Sokaktaki Dostlarim", "we", "us" or "our") collects, protects and uses
        the personally identifiable information ("Personal Information") you
        ("User", "you" or "your") may provide in the Sokaktaki Dostlarim mobile
        application and any of its products or services (collectively, "Mobile
        Application" or "Services"). It also describes the choices available to
        you regarding our use of your Personal Information and how you can
        access and update this information. This Policy does not apply to the
        practices of companies that we do not own or control, or to individuals
        that we do not employ or manage.
        <br></br>
        <br></br>
        <b>Automatic collection of information </b>
        When you open the Mobile Application our servers automatically record
        information that your device sends. This data may include information
        such as your device's IP address and location, device name and version,
        operating system type and version, language preferences, information you
        search for in our Mobile Application, access times and dates, and other
        statistics.
        <br></br>
        <br></br>
        <b>Collection of personal information </b>
        You can visit the Mobile Application without telling us who you are or
        revealing any information by which someone could identify you as a
        specific, identifiable individual. If, however, you wish to use some of
        the Mobile Application's features, you will be asked to provide certain
        Personal Information (for example, your name and e-mail address). We
        receive and store any information you knowingly provide to us when you
        create an account, or fill any online forms in the Mobile Application.
        When required, this information may include your email address, name,
        phone number, address, bank information, or other Personal Information.
        You can choose not to provide us with your Personal Information, but
        then you may not be able to take advantage of some of the Mobile
        Application's features. Users who are uncertain about what information
        is mandatory are welcome to contact us.
        <br></br>
        <br></br>
        <b>Managing personal information </b>
        You are able to access, add to, update and delete certain Personal
        Information about you. The information you can view, update, and delete
        may change as the Mobile Application or Services change. When you update
        information, however, we may maintain a copy of the unrevised
        information in our records. Some information may remain in our private
        records after your deletion of such information from your account. We
        will retain and use your Personal Information for the period necessary
        to comply with our legal obligations, resolve disputes, and enforce our
        agreements unless a longer retention period is required or permitted by
        law. We may use any aggregated data derived from or incorporating your
        Personal Information after you update or delete it, but not in a manner
        that would identify you personally. Once the retention period expires,
        Personal Information shall be deleted. Therefore, the right to access,
        the right to erasure, the right to rectification and the right to data
        portability cannot be enforced after the expiration of the retention
        period.
        <br></br>
        <br></br>
        <b>Use and processing of collected information </b>
        Any of the information we collect from you may be used to personalize
        your experience; improve our Mobile Application; improve customer
        service and respond to queries and emails of our customers; send
        newsletters; send notification emails such as password reminders,
        updates, etc; run and operate our Mobile Application and Services.
        Information collected automatically is used only to identify potential
        cases of abuse and establish statistical information regarding Mobile
        Application traffic and usage. This statistical information is not
        otherwise aggregated in such a way that would identify any particular
        user of the system.
        <br></br>
        <br></br>
        We may process Personal Information related to you if one of the
        following applies: (i) You have given your consent for one or more
        specific purposes. Note that under some legislations we may be allowed
        to process information until you object to such processing (by opting
        out), without having to rely on consent or any other of the following
        legal bases below. This, however, does not apply, whenever the
        processing of Personal Information is subject to European data
        protection law; (ii) Provision of information is necessary for the
        performance of an agreement with you and/or for any pre-contractual
        obligations thereof; (iii) Processing is necessary for compliance with a
        legal obligation to which you are subject; (iv) Processing is related to
        a task that is carried out in the public interest or in the exercise of
        official authority vested in us; (v) Processing is necessary for the
        purposes of the legitimate interests pursued by us or by a third party.
        In any case, we will be happy to clarify the specific legal basis that
        applies to the processing, and in particular whether the provision of
        Personal Information is a statutory or contractual requirement, or a
        requirement necessary to enter into a contract.
        <br></br>
        <br></br>
        <b>Information transfer and storage </b>
        Depending on your location, data transfers may involve transferring and
        storing your information in a country other than your own. You are
        entitled to learn about the legal basis of information transfers to a
        country outside the European Union or to any international organization
        governed by public international law or set up by two or more countries,
        such as the UN, and about the security measures taken by us to safeguard
        your information. If any such transfer takes place, you can find out
        more by checking the relevant sections of this document or inquire with
        us using the information provided in the contact section.
        <br></br>
        <br></br>
        <b>The rights of users </b>
        You may exercise certain rights regarding your information processed by
        us. In particular, you have the right to do the following: (i) you have
        the right to withdraw consent where you have previously given your
        consent to the processing of your information; (ii) you have the right
        to object to the processing of your information if the processing is
        carried out on a legal basis other than consent; (iii) you have the
        right to learn if information is being processed by us, obtain
        disclosure regarding certain aspects of the processing and obtain a copy
        of the information undergoing processing; (iv) you have the right to
        verify the accuracy of your information and ask for it to be updated or
        corrected; (v) you have the right, under certain circumstances, to
        restrict the processing of your information, in which case, we will not
        process your information for any purpose other than storing it; (vi) you
        have the right, under certain circumstances, to obtain the erasure of
        your Personal Information from us; (vii) you have the right to receive
        your information in a structured, commonly used and machine readable
        format and, if technically feasible, to have it transmitted to another
        controller without any hindrance. This provision is applicable provided
        that your information is processed by automated means and that the
        processing is based on your consent, on a contract which you are part of
        or on pre-contractual obligations thereof.
        <br></br>
        <br></br>
        <b>The right to object to processing </b>
        Where Personal Information is processed for the public interest, in the
        exercise of an official authority vested in us or for the purposes of
        the legitimate interests pursued by us, you may object to such
        processing by providing a ground related to your particular situation to
        justify the objection. You must know that, however, should your Personal
        Information be processed for direct marketing purposes, you can object
        to that processing at any time without providing any justification. To
        learn, whether we are processing Personal Information for direct
        marketing purposes, you may refer to the relevant sections of this
        document.
        <br></br>
        <br></br>
        <b>How to exercise these rights </b>
        Any requests to exercise User rights can be directed to the Owner
        through the contact details provided in this document. These requests
        can be exercised free of charge and will be addressed by the Owner as
        early as possible.
        <br></br>
        <br></br>
        <b>Privacy of children </b>
        We recognize the need to provide further privacy protections with
        respect to Personal Information we may collect from children and take
        many special precautions to protect the privacy of children. We do not
        require any Personal Information from them at any time. We encourage
        children to consult with their parents before submitting any information
        to any Mobile Application. We believe parents should be involved in the
        online activities of their children and suggest that parents do their
        best to provide their children with a safe and friendly online
        environment.
        <br></br>
        <br></br>
        <b>Newsletters </b>
        We offer electronic newsletters to which you may voluntarily subscribe
        at any time. You may choose to stop receiving our newsletter or
        marketing emails by following the unsubscribe instructions included in
        these emails or by contacting us. However, you will continue to receive
        essential transactional emails.
        <br></br>
        <br></br>
        <b>Advertisement </b>
        We may display online advertisements and we may share aggregated and
        non-identifying information about our customers that we collect through
        the registration process or through online surveys and promotions with
        certain advertisers. We do not share personally identifiable information
        about individual customers with advertisers. In some instances, we may
        use this aggregated and non-identifying information to deliver tailored
        advertisements to the intended audience.
        <br></br>
        <br></br>
        <b>Affiliates </b>
        We may disclose information about you to our affiliates for the purpose
        of being able to offer you related or additional products and services.
        Any information relating to you that we provide to our affiliates will
        be treated by those affiliates in accordance with the terms of this
        Privacy Policy.
        <br></br>
        <br></br>
        <b>Links to other mobile applications </b>
        Our Mobile Application contains links to other mobile applications that
        are not owned or controlled by us. Please be aware that we are not
        responsible for the privacy practices of such other mobile applications
        or third-parties. We encourage you to be aware when you leave our Mobile
        Application and to read the privacy statements of each and every mobile
        application that may collect Personal Information.
        <br></br>
        <br></br>
        <b>Information security </b>
        We secure information you provide on computer servers in a controlled,
        secure environment, protected from unauthorized access, use, or
        disclosure. We maintain reasonable administrative, technical, and
        physical safeguards in an effort to protect against unauthorized access,
        use, modification, and disclosure of Personal Information in its control
        and custody. However, no data transmission over the Internet or wireless
        network can be guaranteed. Therefore, while we strive to protect your
        Personal Information, you acknowledge that (i) there are security and
        privacy limitations of the Internet which are beyond our control; (ii)
        the security, integrity, and privacy of any and all information and data
        exchanged between you and our Mobile Application cannot be guaranteed;
        and (iii) any such information and data may be viewed or tampered with
        in transit by a third-party, despite best efforts.
        <br></br>
        <br></br>
        <b>Data breach </b>
        In the event we become aware that the security of the Mobile Application
        has been compromised or users Personal Information has been disclosed to
        unrelated third parties as a result of external activity, including, but
        not limited to, security attacks or fraud, we reserve the right to take
        reasonably appropriate measures, including, but not limited to,
        investigation and reporting, as well as notification to and cooperation
        with law enforcement authorities. In the event of a data breach, we will
        make reasonable efforts to notify affected individuals if we believe
        that there is a reasonable risk of harm to the user as a result of the
        breach or if notice is otherwise required by law. When we do, we will
        post a notice in the Mobile Application, send you an email.
        <br></br>
        <br></br>
        <b>Legal disclosure </b>
        We will disclose any information we collect, use or receive if required
        or permitted by law, such as to comply with a subpoena, or similar legal
        process, and when we believe in good faith that disclosure is necessary
        to protect our rights, protect your safety or the safety of others,
        investigate fraud, or respond to a government request.
        <br></br>
        <br></br>
        <b>Changes and amendments </b>
        We may update this Privacy Policy from time to time in our discretion
        and will notify you of any material changes to the way in which we treat
        Personal Information. When changes are made, we will send you an email
        to notify you. We may also provide notice to you in other ways in our
        discretion, such as through contact information you have provided. Any
        updated version of this Privacy Policy will be effective immediately
        upon the posting of the revised Privacy Policy unless otherwise
        specified. Your continued use of the Mobile Application or Services
        after the effective date of the revised Privacy Policy (or such other
        act specified at that time) will constitute your consent to those
        changes. However, we will not, without your consent, use your Personal
        Data in a manner materially different than what was stated at the time
        your Personal Data was collected.
        <br></br>
        <br></br>
        <b>Deleting your personal data (the right to be forgotten) </b>
        If your personal data is no longer needed or is being used unlawfully
        then you can ask for your data to be erased. This is known as "the right
        to be forgotten". These rules also apply to search engines, such as
        Google, as they're also considered to be data controllers. You can ask
        for links to web pages including your name to be removed from search
        engine results, if the information is inaccurate, inadequate, irrelevant
        or excessive. If a company has made your personal data available online
        and you ask for them to be deleted, the company also has to inform any
        other websites where they've been shared that you've asked for your data
        and links to them to be deleted. To protect other rights, such as
        freedom of expression, some data may not be automatically deleted. For
        example, controversial statements made by people in the public eye,
        might not be deleted if public interest is best served by keeping them
        online. In order to delete your account please follow the instructions
        on the app in settings page. If you want your account deleted manually,
        please contact us from contact@sokaktakidostlarim.com
        <br></br>
        <br></br>
        <b>Acceptance of this policy </b>
        You acknowledge that you have read this Policy and agree to all its
        terms and conditions. By using the Mobile Application or its Services
        you agree to be bound by this Policy. If you do not agree to abide by
        the terms of this Policy, you are not authorized to use or access the
        Mobile Application and its Services.
        <br></br>
        <br></br>
        <b>Third Parties </b>
        This applications also contains third party libraries such as Facebook,
        Apple and Google. You acknowledge that you have read their Policy and
        agree to all its terms and conditions.
        <br></br>
        <br></br>
        <b>Contacting us </b>
        If you would like to contact us to understand more about this Policy or
        wish to contact us concerning any matter relating to individual rights
        and your Personal Information, you may send an email to
        contact@sokaktakidostlarim.com
        <br></br>
        <br></br>
        This document was last updated on Jan 1, 2023
      </p>
    </div>
  );
}

export default PrivacyPolicy;
